import { Delete } from "@mui/icons-material";
import { Grid, IconButton, TablePagination } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useCustomParams from "components/shared/useCustomParams";
import columnsConfig from "config/advancedTableColumnsControl.json";
import { Context } from "context/Wrapper";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';

function AdvancedTable({
  data,
  collectionName = "",
  columnsRaw,
  handleDelete = false,
  rowCount = 0,
  handle2FA,
  isInactive
}) {
  const navigate = useNavigate();
  const context = useContext(Context);

  const { params, updateParams } = useCustomParams();


  // useEffect(() => {
  //   updateParams({
  //     page: false,
  //     pageSize: false,
  //     pageIncreasing: false,
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  const columns = useMemo(
    () => [
      ...columnsRaw?.map((col) => ({
        headerClassName: "forms-header",
        flex: 1,
        ...col,
      })),

      !isInactive && {
        field: "actions",
        headerName: "Actions",
        // flex: 1,
        headerClassName: "forms-header",
        renderCell: ({ row }) => (
          <Grid item xs={1} md={1} lg={1}>
            <IconButton onClick={(e) => e.stopPropagation()}>
              <Delete
                onClick={() => handleDelete(row.id, row.customerReference, row)}
              />
            </IconButton>
          </Grid>
        ),
      },

      collectionName == "profiles" && {
        field: "2FA",
        headerName: "2FA",
        // flex: 1,
        headerClassName: "forms-header",
        renderCell: ({ row }) => (
          <Grid item xs={1} md={1} lg={1}>
            <IconButton onClick={(e) => e.stopPropagation()}>
            <Switch
            checked={row.enforceTwoFactor ?? true} 
            onChange={(e)=> handle2FA(row, e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
            </IconButton>
          </Grid>
        ),
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsRaw]
  );

 

  const handleOpenForm = (id) => {
    navigate(`/${collectionName}/details/${id}`);
  };

  const getGroupClass = (row) =>
    row?.userId !== context?.state?.userProfile?.userId &&
    row?.groupId === context?.state?.userProfile?.groupId;

  const pageSize =
    Number(params.get("pageSize")) || columnsConfig?.defaultRowsLimit,
    page = Number(params.get("page")) || 0;


  return (
    <>
      <DataGrid
        // key={`${pageSize}-${context?.state?.showAllDataFlag}`}
        // key={`${rowCount}-${data.length}-${page}-${pageSize}-${context?.state?.showAllDataFlag}`}
        pagination={data?.length === rowCount}
        hideFooter={data?.length !== rowCount}
        sx={{ maxWidth: "98vw", overflow: "auto", marginTop: "20px" }}
        rows={data}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: columnsConfig?.[collectionName] || [],
          },
        }}
        autoHeight={true}
        onPaginationModelChange={(params) => {
          if (data?.length === rowCount)
            updateParams({
              page: params.page,
              pageSize: params.pageSize,
            });
          
        }}
       
        pageSizeOptions={[10, 25, 50, 100]}
        // rowsPerPageOptions={[5, 10, 25, 50, 100]}
        paginationModel={{ pageSize, page }}
        getRowClassName={(params) =>
          `table-row--${getGroupClass(params.row) ? "secondary" : "main"}`
        }
        onFilterModelChange={(e) => {
          updateParams({
            pageSize: false,
            page: false,
            search: e?.items?.map((item) => item?.value).join("+"),
          });
        }}
        onRowClick={(e) => handleOpenForm(e?.row?.id)}
        slots={{
          toolbar: GridToolbar,
        }}
      />
      {data?.length !== rowCount && (
        <TablePagination
          key={`${rowCount}-${data.length}-${page}-${pageSize}`}
          component="div"
          count={rowCount}
          page={page}
          onPageChange={(_, e) => {
            updateParams({
              page: e,
               pageIncreasing: `${e > Number(params.get("page"))}`,
            });
          
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            updateParams({ pageSize: `${e.target.value}`, page: false });
          }}
        />
       )} 
    </>
  );
}

export default memo(AdvancedTable);









