import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

// Reusable function for creating a new document with unique field
export const CreateUniqueDocument = async ({
    openAddedSuccessfully,
    navigate,
    db,
    collectionName,
    data,
    uniqueFieldName = 'customerReference',
    loadingCallback,
    failureCallback,
    duplicateCallback
}) => {


    loadingCallback && loadingCallback();
    const uniqueFieldValue = data[uniqueFieldName].trim(); // Normalizing the unique field value
    
    try {
        // Query to check if any document with the same unique field already exists
        const querySnapshot = await getDocs(collection(db, collectionName));
        // Check if any document has the normalized value in the unique field
        let isDuplicate = false;
        
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if ((data[uniqueFieldName] || '').toLowerCase() === uniqueFieldValue.toLowerCase()) {
                isDuplicate = true;
            }
        });

        if (!isDuplicate) {
            // If no duplicates, proceed to create the new document with the original input
            addDoc(collection(db, collectionName), {
                ...data,
                [uniqueFieldName]: uniqueFieldValue, // Store original version as the user inputted it
            }).then((docRef) => {
                openAddedSuccessfully();
                setTimeout(() => {
                    navigate(`/${collectionName}/details/${docRef.id}`);
                }, 1500); 
                // navigate(`/${collectionName}/details/${docRef.id}`);
            });

        } else {
            duplicateCallback && duplicateCallback(); // Call duplicate callback if provided
        }
    } catch (error) {
        console.error(`Error checking uniqueness for ${collectionName}: `, error);
        failureCallback && failureCallback(); // Call failure callback if provided
    }
};
