import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
import { checkIsProduction } from 'config/config';

export const sendEmailVerificationLink = async (email) => {
  const auth = getAuth();


  // const baseUrl = window.location.origin;
  let isProduction = checkIsProduction();
  console.log(isProduction);

  const actionCodeSettings = {
    url: isProduction ? 'https://nyc.xpdtr.com/verify' : 'https://dev-nyc.xpdtr.com/verify', // URL where the user will be redirected after clicking the link
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    localStorage.setItem('emailForSignIn', email);
    // Show SweetAlert success message
    Swal.fire({
      icon: 'success',
      title: 'Email Sent!',
      text: 'A verification link has been sent to your email. Please check your inbox.',
      confirmButtonText: 'OK',
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Error sending email verification link. Please try again later.',
      confirmButtonText: 'OK',
    });
    console.error('Error sending email verification link:', error);
  }
};
