import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Fab,
  MenuItem,
  Snackbar,
  IconButton,
  InputAdornment
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Save, Close } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import { Context } from "context/Wrapper";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import { or, where } from "firebase/firestore";
import { db } from "config/config";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";
import { CreateUniqueDocument } from "components/shared/createUniqueDocument ";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const BuildingNew = (props) => {
  const navigate = useNavigate();

  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });

  const [buildingDetail, setBuildingDetail] = useState({});

  const [open, setOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);
  const [duplicateOpen, setduplicateOpen] = React.useState(false);
  const [SubmitLoader, setSubmitLoader] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);

  const [addedSuccessfullyOpen,setAddedSuccessfullyOpen]=React.useState(false)



  const openAddedSuccessfully = () => {
    setAddedSuccessfullyOpen(true);
  };

  const handleCloseAddedSuccessfully = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddedSuccessfullyOpen(false);
  };



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenFailure(false);
  };

  const handleduplicateOpen = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setduplicateOpen(false);
  };

  const handleChange = (e) => {
    let currentBuildingDetail = buildingDetail;
    currentBuildingDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setBuildingDetail({ ...currentBuildingDetail });
  };

  // const onSubmit = (data) => {
  //   db.collection("buildings")
  //     .add({
  //       ...buildingDetail,
  //       userId: user.uid,
  //       groupId: groupId,
  //     })
  //     .then((docRef) => {
  //       navigate(`/buildings/details/${docRef.id}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error adding document: ", error);
  //     });
  // };

  // const onSubmit = (data) => {
  //   const customerReference = buildingDetail.customerReference.toUpperCase().trim();
  //   db.collection("buildings")
  //     .where("customerReference", "==", customerReference)
  //     .get()
  //     .then((querySnapshot) => {
  //       if (querySnapshot.empty) {
  //         // If no matching document is found, add the new building
  //         db.collection("buildings")
  //           .add({
  //             ...buildingDetail,
  //             customerReference:customerReference.toUpperCase(),
  //             userId: user.uid,
  //             groupId: groupId,
  //           })
  //           .then((docRef) => {
  //             navigate(`/buildings/details/${docRef.id}`);
  //           })
  //           .catch((error) => {
  //             console.error("Error adding document: ", error);
  //           });
  //       } else {
  //         // If a matching document is found display an error
  //         setduplicateOpen(true)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error checking for unique customerReference: ", error);
  //     });
  // };

  const onSubmit = (data) => {
    CreateUniqueDocument({
      openAddedSuccessfully,
      navigate:navigate,
      db:db,
      collectionName: "buildings",
      data: {
        ...buildingDetail,
        userId: user.uid,
        groupId: groupId
      },
      uniqueFieldName: "customerReference", // The field that should be unique

      failureCallback: () => {
        console.error("Error adding document.");
        setSubmitLoader(false)
      },
      duplicateCallback: () => {
        setduplicateOpen(true); // Handle duplicate case
        setSubmitLoader(false)
      },
      loadingCallback : ()=>{
        setSubmitLoader(true)
      }
    });
  };
  

  const saveAndClose = () => {
    onSubmit(buildingDetail);
  };

  const discardAndClose = () => {
    navigate("/buildings");
  };

  const handleCloseBuilding = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/buildings");
  };

  const hasRequiredFields = () => {
    return (
      buildingDetail.customerReference &&
      buildingDetail.houseNumber &&
      buildingDetail.streetName
    );
  };

  return (
    <>
      <Typography
        sx={{ textTransform: "uppercase", marginBottom: "1rem" }}
        align="left"
        variant="h4"
      >
        New Building
      </Typography>

      <form name="buildingForm" id="buildingForm">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Name"
              variant="outlined"
              name="customerReference"
              value={buildingDetail.customerReference}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
         
          <Grid item xs={12} md={6} lg={4}>
        <TextField
       label={"Borough"}
       name="borough"
       select
       onChange={handleChange}
      value={buildingDetail.borough || ""} 
      fullWidth
       InputProps={{
      endAdornment: buildingDetail.borough ? (
        <InputAdornment position="end">
          <IconButton
            onClick={() => handleChange({ target: { name: 'borough', value: '' } })} 
            style={{ marginRight: '10px' }} 
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ) : null
    }}
  >
    <MenuItem value={"bk"}>Brooklyn</MenuItem>
    <MenuItem value={"mh"}>Manhattan</MenuItem>
    <MenuItem value={"bx"}>Bronx</MenuItem>
    <MenuItem value={"qn"}>Queens</MenuItem>
    <MenuItem value={"si"}>Staten Island</MenuItem>
  </TextField>
</Grid>


          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="House number"
              variant="outlined"
              name="houseNumber"
              value={buildingDetail.houseNumber}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Floor(s)"
                variant="outlined"
                name="floors"
                value={buildingDetail.floors}
                onChange={handleChange}
                fullWidth

              />
            </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Street name"
              variant="outlined"
              name="streetName"
              value={buildingDetail.streetName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="City"
              variant="outlined"
              name="city"
              value={buildingDetail.city}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="State"
              variant="outlined"
              name="state"
              value={buildingDetail.state}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="ZIP"
              variant="outlined"
              name="ZIP"
              value={buildingDetail.ZIP}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="BIN"
              variant="outlined"
              name="BIN"
              value={buildingDetail.BIN}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Block"
              variant="outlined"
              name="block"
              value={buildingDetail.block}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Lot"
              variant="outlined"
              name="lot"
              value={buildingDetail.lot}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="CB No."
              variant="outlined"
              name="cb"
              value={buildingDetail.cb}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Owner"}
              name="owner"
              select
              onChange={handleChange}
              value={buildingDetail.owner}
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={buildingDetail.owner}
                name="owner"
                inputLabel="Owner"
              />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Tenant"}
              name="tenant"
              select
              onChange={handleChange}
              value={buildingDetail.tenant}
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={buildingDetail.tenant}
                inputLabel="Tenant"
                name="tenant"
              />
          </Grid>
        </Grid>
      </form>
      <SaveCloseDialog
        open={dialogOpen}
        saveAndClose={saveAndClose}
        discardAndClose={discardAndClose}
        setDialogOpen={setDialogOpen}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!props.isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={saveAndClose}
            form="buildingForm"
            disabled={SubmitLoader || (!isDirty && !hasRequiredFields()) || props.isInactive}
          >
            <Save sx={{ mr: 1 }} />
            {/* Save */}
            Add
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseBuilding}
        >
          <Close sx={{ mr: 1 }} />
          Cancel
        </Fab>
      </Box>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Building successfully saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>

      <Snackbar
        open={duplicateOpen}
        autoHideDuration={2000}
        onClose={handleduplicateOpen}
      >
        <Alert
          onClose={handleduplicateOpen}
          severity="error"
          sx={{ width: "100%" }}
        >
          Building name already exists!
        </Alert>
      </Snackbar>

      <Snackbar
      open={addedSuccessfullyOpen}
      autoHideDuration={2000}
      onClose={handleCloseAddedSuccessfully}
    >
      <Alert
        onClose={handleCloseAddedSuccessfully}
        severity="success"
        sx={{ width: "100%" }}
      >
       Building added successfully!
      </Alert>
    </Snackbar>

    </>
  );
};
